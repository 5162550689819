import React, { FC } from 'react';
import { Grid, Pagination as Pagination, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { AxiosResponse } from 'axios';
import { createSearchParams, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { nestedSort } from '../../utils/event-utils';
import { OneHundredMileAwardSmall } from '../award-lottie';

interface RunnerListingByName {
    data: any;
    link: string;
}



export const RunnerListingByName: FC<RunnerListingByName> = ({data, link}) =>
{ 
    let {page} = useParams();
    let pageNum = 1;

    if (!page) {
        pageNum = 1;
    } else {
        pageNum = Number(page);
    }

    var delay = 0;

    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();

    const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
        navigate({
            pathname: `/${link}/${value}`,
            search: searchParams.toString(),
    
        });
      };

      // Navigation function to view an individual runner
      function viewRunner(row: any) {
        navigate({
            pathname: `/runner/${row.row.slug}`
    
        });
    }



    return <>
    <Grid container spacing={1} justifyContent="center" alignItems="center">
        <Grid item md={12} lg={10} xl={8}>
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 450 }} aria-label="Runner table">
                    <TableHead>
                    <TableRow>
                        <TableCell width="20%">Name</TableCell>
                        <TableCell align="right" width="5%">Age</TableCell>
                        <TableCell align="right" width="20%">Hometown</TableCell>
                        <TableCell align="right" width="20%">Performances</TableCell>
                        <TableCell align="right" width="20%">Longest Streak</TableCell>
                        <TableCell align="right" width="15%">Awards</TableCell>
                    </TableRow>
                    </TableHead>
                    <TableBody>
                    {data.data.rows.map((row: any) => {

                        delay = delay + 250;

                        const highAge = row.performance.sort(nestedSort("age", null, null, "DESC"));
                        

                        // Include the 5K and 10M total races run here.
                       let totalFiveK = '';
                        if (row.longestStreakFiveK) {
                            var length;
                            length = row.performance.filter((perf: any) => ['5K', 'V5K'].includes(perf.event.eventType)).sort(nestedSort("age", null, null, "DESC"));
                            totalFiveK = `${length.length} (${length[length.length - 1].event.year})`;
                            /* row.performance.length > 1 ? totalFiveK = `${totalFiveK.slice(0, -1)} - ${length[0].event.year})` : totalFiveK; */
                        }

                        let totalTenM = '';
                        if (row.longestStreak) {
                            var length;
                            length = row.performance.filter((perf: any) => ['10M', 'V10M'].includes(perf.event.eventType)).sort(nestedSort("age", null, null, "DESC"));
                            totalTenM = `${length.length} (${length[length.length - 1].event.year})`;
                            /* row.performance.length > 1 ? totalTenM = `${totalTenM.slice(0, -1)} - ${length[0].event.year})` : totalTenM; */
                        }
                        
                        

                        return <TableRow
                        hover
                        onClick = {() => viewRunner({row})}
                        key={row.id}
                        
                        sx={{ '&:last-child td, &:last-child th': { border: 0 }, cursor: 'pointer'}}
                        >
                    
                        <TableCell>{row.firstName} {row.middleInitial} {row.lastName}</TableCell>
                        <TableCell align="right">{highAge[0] !== undefined ? highAge[0].age : ""}</TableCell>
                        <TableCell align="right">{row.city}, {row.state}</TableCell>
                        <TableCell align="right">
                            {row.longestStreak > 0 && `10 Mile Run: ${totalTenM}`} <br />
                            {row.longestStreakFiveK > 0 && `5K Run: ${totalFiveK}`}                            
                        </TableCell>
                        <TableCell align="right">
                            {row.longestStreak > 1 && `10 Mile Run: ${row.longestStreak} (${row.longestStreakStart} - ${row.longestStreakEnd})`} <br />
                            {row.longestStreakFiveK > 1 && `5K Run: ${row.longestStreakFiveK} (${row.longestStreakStartFiveK} - ${row.longestStreakEndFiveK})`}      
                        </TableCell>
                        <TableCell align="right">
                            {
                                Number(row.totalDistance) >= 100 ? <OneHundredMileAwardSmall id={`${row.id}-lottie-award`} text="100 Mile Club" delay={delay}></OneHundredMileAwardSmall> : <></>
                            }
                        </TableCell>
                        </TableRow>
                    })}
                    </TableBody>
                </Table>
            </TableContainer>
        </Grid>
    </Grid>
    <Grid container spacing={1} justifyContent="center" alignItems="center">
        <Grid item md={12} lg={10} xl={8} alignContent="right">
            <Pagination showFirstButton showLastButton count={Math.ceil(Number(data.data.count) / 10)} page={pageNum} onChange={handleChange} color="secondary" />
        </Grid>
    </Grid>
    </>
}