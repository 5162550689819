import React, {FC, useState} from 'react';
import { Grid, Pagination as Pagination, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow } from '@mui/material';
import { findPace, identifyDivision, nestedSort } from '../../utils/event-utils';
import {TisTid} from '../tid-tis';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { viewPerformancesBySearch } from '../../utils/axios-query';
import { useQuery } from '@tanstack/react-query';

interface RunnerAgeInterface {
    year?: string | null;
    division?: string | null;
    eventType: string[];
    page?: string | null;
}

export const RunnerAgeEventListing: FC<RunnerAgeInterface> = ({eventType, year, division = "W"}) => {

    
    const [searchParams, setSearchParams] = useSearchParams();

    let page = searchParams.get("page");
    let pageNum = 1;

    if (!page) {
        pageNum = 1;
    } else {
        pageNum = Number(page);
    }
    const [pageid, setPage] = useState(pageNum);

    const handlePageChange = (event: any, newPage: number) => {
        let updatedSearchParams = new URLSearchParams(searchParams.toString());
        updatedSearchParams.set('page', `${newPage}`);
        setSearchParams(updatedSearchParams.toString());

       setPage(newPage);
    }

    // We need to loop over the events and make a listing to display.
    const navigate = useNavigate();
    // Navigation function to view an individual runner
    function viewPerformance(row: any) {
        navigate({
            pathname: `/performance/${row.row.id}`

        });
    }

    
    const {isLoading, data, isError, error} = useQuery({
        queryKey: ['runnerListing', pageNum, {eventType: eventType.join(), year: year, division: division, page: pageNum}],
        keepPreviousData: true,
        queryFn: () => viewPerformancesBySearch(eventType, Number(year), division!, pageNum),
    });

    if (isLoading) {
        return ( <div className="loadingdata">Searching Database...</div> )
    }

    if (isError) {
        console.log(error);
        return (<div className="errordata">Unable to load results</div>)
    }
    
    if (data)
    {
        if (Number(data.data.performanceCount) > 0)
        {
            
            return (<>
             <Grid container spacing={1} justifyContent="center" alignItems="center">
                    <Grid item md={12} lg={10} xl={8} alignContent="right">
                        {/* <Pagination showFirstButton showLastButton count={Math.floor(Number(data.data.count) / 10)} page={pageNum} onChange={handleChange} color="secondary" /> */}
                        <Pagination showFirstButton showLastButton 
                            count={Math.ceil(Number(data.data.performanceCount) / 10)} 
                            page={pageNum} 
                            onChange={handlePageChange} 
                            color="secondary" 
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={1} justifyContent="center" alignItems="center" component={Paper}>
                    <Grid item md={12}>
                        <TableContainer component={Paper}>
                            <Table sx={{ minWidth: 450 }} aria-label="Runner table">
                                <TableHead>
                                <TableRow>
                                    <TableCell width="20%">Name</TableCell>
                                    <TableCell align="right" width="20%">PiD / TiD</TableCell>
                                    <TableCell align="right" width="20%">PiS / TiS</TableCell>
                                    <TableCell align="right" width="5%">Age</TableCell>
                                    <TableCell align="right" width="15%">Time</TableCell>
                                    <TableCell align="right" width="10%">Pace</TableCell>

                                    <TableCell align="right" width="10%">Division</TableCell>

                                    <TableCell align="right" width="20%">Hometown</TableCell>
                                </TableRow>
                                </TableHead>
                                <TableBody>
                                    
                                {data.data.performance
                                .map((row: any) => {
                                    let distance: number = 10;
                                    
                                    if (["5K", "V5K"].includes(row.event.eventType)) {
                                        distance = 3.10686;
                                    }

                                    

                                    return <TableRow
                                    hover
                                    onClick = {() => viewPerformance({row})}
                                    key={row.id}
                                    
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 }, cursor: 'pointer'}}
                                    >
                                
                                    <TableCell>{row.runner.firstName} {row.runner.lastName} ({row.runner.sex})</TableCell>
                                    <TableCell align="right">{row.pid} / {row.tid}{/* <TisTid eventId={row.event.id} division={row.division} /> */}</TableCell>
                                    <TableCell align="right">{row.pis} / {row.tis}{/* <TisTid eventId={row.event.id} sex={row.runner.sex} /> */}</TableCell>
                                    <TableCell align="right">{row.age}</TableCell>
                                    <TableCell align="right">{row.netTime}</TableCell>
                                    <TableCell align="right">{findPace(row.netTime, distance)}</TableCell>

                                    <TableCell align="right">{identifyDivision(row.age, row.runner.sex, row.division)}</TableCell>

                                    <TableCell align="right">{row.homeTown}</TableCell>
                                    </TableRow>
                                })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                </Grid>
                <Grid container spacing={1} justifyContent="center" alignItems="center">
                    <Grid item md={12} lg={10} xl={8} alignContent="right">
                        {/* <Pagination showFirstButton showLastButton count={Math.floor(Number(data.data.count) / 10)} page={pageNum} onChange={handleChange} color="secondary" /> */}
                        <Pagination showFirstButton showLastButton 
                            count={Math.ceil(Number(data.data.performanceCount) / 10)} 
                            page={pageNum} 
                            onChange={handlePageChange} 
                            color="secondary" 
                        />
                    </Grid>
                </Grid>
            </>)
    
        }
    }

    return (<></>);
}
