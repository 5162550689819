import React, {FC} from 'react';
import {useQuery} from '@tanstack/react-query';
import { RunnerListingByName } from './runnerlisting-name';
import { SearchOperators, updateRunnerList } from '../../utils/axios-query';

export const RunnerListingView: FC<SearchOperators> = ({
    field, firstName, lastName, age, division, eventType, page, slug, orderBy, link = "runner-name"
}) => {

    if (!page) {
        page = 1;
    }



    const {isLoading, data, isError, error} = useQuery({
        queryKey: ['runnerListing', page, {firstName: firstName, lastName: lastName, age: age, division: division, eventType: eventType, slug: slug, orderBy: orderBy}],
        keepPreviousData: true,
        queryFn: () => updateRunnerList(firstName, lastName, age, division, eventType, page, slug, orderBy),
    });

    if (isLoading) {
        return ( <div className="loadingdata">Searching Database...</div> )
    }

    if (isError) {
        console.log(error);
        return (<div className="errordata">Unable to load results</div>)
    }
    
    if (data)
    {
        if (Number(data.data.count) > 0)
        {
            return  (
            <RunnerListingByName data={data} link={link} />
            )
        }
    }

    return (<></>)

    
    
}