import { AxiosResponse } from "axios";
import { getUser } from "./axios-query";

export async function checkLogin() {
    // decode JSON into a variable "user"

    var response: AxiosResponse<any, any> = await getUser();

    if (response.data === undefined || response.data === null) {
        return false;
    }
    
    
    var user: any = response.data;

    if (Number(user.id)) {
        return true;
    } else {
        return false;
    }
}