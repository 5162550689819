import { Grid } from '@mui/material';
import React from 'react';
import { OneHundredMileAwardSmall } from '../fragments/award-lottie';
import { RunnerSearchForm } from '../fragments/runners/runner-search';

export function SearchRunnerName() {
    return <>
    <Grid container spacing={1} justifyContent="center" alignItems="center">
        <Grid item md={12} lg={10} xl={8} alignContent="right">
            <h1>Search For Runner By Name</h1>
            
        </Grid>
    </Grid>
        <RunnerSearchForm />
    </>;
}