import React, { useEffect, useState } from 'react';
import {useQuery} from '@tanstack/react-query';
import { viewRunnerBySlug } from '../utils/axios-query';
import { useParams } from 'react-router-dom';
import { eventTotals, expandNames, findPace, removeDuplicates } from '../utils/event-utils';
import { RunnerSingleEventListing } from '../fragments/runner-single/runner-single-events';
import { DetailedRunnerStats } from '../fragments/runner-single/runner-single-stats';
import { checkLogin } from '../utils/checkuser';
import { LightboxAward } from '../fragments/award-lottie';




export function Runner() {

    let {slug} = useParams();
    const [loggedIn, setLoggedIn] = useState(false);

    useEffect(() => {
        // check if the user is logged in here
        // update the `loggedIn` state based on the result
        checkLogin().then((result) => setLoggedIn(result));
      }, []);
    
    const {isLoading, data, isError, isSuccess, error} = useQuery({
        queryKey: ['viewRunner', slug],
        queryFn: () => viewRunnerBySlug(slug),
        
    });


    if (isLoading) {
        return ( <div className="loadingdata">Searching for Runner...</div> )
    }

    if (isError) {
        console.log(error);
        return (<div className="errordata">Unable to load runner at this time</div>)
    }
    
    if (isSuccess)
    {

        const runner = data.data[0];

        const totals = eventTotals(runner.performance);

        

        var eventTypes: Array<string> = [];

        runner.performance.map((row: any) => {
            
                eventTypes.push(row.event.eventType);
            
        });

        const uniqueEvents = removeDuplicates(eventTypes);
        
        return  (
            <>
            {loggedIn ? (
        <p>Welcome, logged in user!</p>
       
      ) : (
        <p>Please log in to see this content.</p>
      )}

        {totals.totalMiles >= 100 ? <LightboxAward></LightboxAward> : null}
            <h1>Runner Details and Statistics for {runner.firstName} {runner.middleInitial} {runner.lastName}</h1>
            <p>Congrats! You have run {Math.round(totals.totalMiles)} miles in Credit Union Cherry Blossom Runs! You've run in {totals.totalRaces} races including our Virtual Events. See your stats below.</p>

            {
                totals.hundredMileClubRemaining <= 0 && <h3>100 Miles Club Runner!</h3>
            }

            {
                totals.hundredMileClubRemaining > 0 && <h3>Just {Math.round(totals.hundredMileClubRemaining)} miles until you reach the 100 mile club!</h3>
            }

            <h2>See all runs</h2>
            <RunnerSingleEventListing sex={runner.sex} performance={runner.performance} />
            <br />

            {
                uniqueEvents.map((eventType: any) => {
                    return (
                        <DetailedRunnerStats eventType={eventType} eventLabel={expandNames(eventType)} performances={runner.performance} />
                    )
                })
            }


    


            </>
        )
        
    }

    return (<></>)
}