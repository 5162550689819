import axios, { AxiosResponse } from "axios";

export interface SearchOperators {
    field: string;
    firstName?: string;
    lastName?: string;
    age?: number;
    division?: string;
    eventType?: string;
    slug?: string;
    page: number;
    orderBy?: string;
    link?: string;
}

axios.defaults.withCredentials = true;

export async function updateRunnerList(firstName: string = "", lastName: string = "", age: number = 0, division: string = "", eventType: string = "", page: number = 1, slug: string = '', orderBy = '') {

    const requestURL = `${process.env.REACT_APP_API_HOST}api/runners/search/${page}?firstName=${encodeURIComponent(firstName)}&lastName=${encodeURIComponent(lastName)}&age=${age}&division=${encodeURIComponent(division)}&eventType=${encodeURIComponent(eventType)}&slug=${slug}&orderBy=${orderBy}`;
    // console.log(requestURL);
    // Send a request to the backend
    return axios.get(requestURL);
    
}


export async function viewRunnerBySlug(slug: string = '') {

    const requestURL = `${process.env.REACT_APP_API_HOST}api/runners/view/${slug}`;
    // console.log(requestURL);
    // Send a request to the backend
    return axios.get(requestURL);
    
}

export async function findRunnerTisTid(eventId: number = 1, sex: string = "", division: string = "") {

    var requestURL:string;
    if (sex) {
        requestURL = `${process.env.REACT_APP_API_HOST}api/performances/tis/${eventId}/${sex}`;
    } else {
        requestURL = `${process.env.REACT_APP_API_HOST}api/performances/tid/${eventId}/${division}`;
    }

    
    // console.log(requestURL);
    // Send a request to the backend
    return axios.get(requestURL);
    
} 



export async function viewPerformancesBySearch(eventType: string[], year: number, division: string, page: number) {

    if (!page) {
        page = 0;
    }

    const requestURL = `${process.env.REACT_APP_API_HOST}api/performances/search/${eventType.join()}/${year}/${division}/${page}`;
    // console.log(requestURL);
    // Send a request to the backend
    return axios.get(requestURL);
    
}

export async function viewPerformanceById(id: number) {

    const requestURL = `${process.env.REACT_APP_API_HOST}api/performances/id/${id}/`;
    // console.log(requestURL);
    // Send a request to the backend
    return axios.get(requestURL);
    
}

export async function registerNewUser(email: string, pass: string) {

    const data = {
        password: pass,
        passwordConfirm: pass,
        email: email,
      };

    const requestURL = `${process.env.REACT_APP_API_HOST}api/auth/admin/register/`;
    // console.log(requestURL);
    // Send a request to the backend
    return axios.post(requestURL, data)
    
}

export async function loginUser(email: string, pass: string) {

    const data = {
        password: pass,
        email: email,
      };

    const requestURL = `${process.env.REACT_APP_API_HOST}api/auth/admin/login/`;
    // console.log(requestURL);
    // Send a request to the backend
    return axios.post(requestURL, data)
    
}


export async function getUser() {

    const requestURL = `${process.env.REACT_APP_API_HOST}api/auth/admin/user/`;
    // console.log(requestURL);
    // Send a request to the backend
    return axios.get(requestURL);
    
}

export async function logout() {

    const requestURL = `${process.env.REACT_APP_API_HOST}api/auth/admin/logout/`;
    // console.log(requestURL);
    // Send a request to the backend
    return axios.get(requestURL);
    
}


export async function getPendingMerges() {

    const requestURL = `${process.env.REACT_APP_API_HOST}api/merges/pending/`;
    // console.log(requestURL);
    // Send a request to the backend
    return axios.get(requestURL);
    
}


export const fetchRunnerById = async (runnerId: string): Promise<AxiosResponse> => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_HOST}/api/runners/${runnerId}`);
      return response;
    } catch (error) {
      throw error;
    }
  };