import { useQuery } from '@tanstack/react-query';
import React, {FC} from 'react';
import { findRunnerTisTid } from '../utils/axios-query';

// This function finds the total in a person's division or sex for a specific race event

interface tisTidvalues {
    eventId: number;
    sex?: string;
    division?: string;

}

export const TisTid: FC<tisTidvalues> = ({eventId, sex = "", division = ""}) => {

    // we need to execute a query to pull the correct value and return it.


        
        const {isLoading, data, isError, isSuccess, error} = useQuery({
            queryKey: ['EventTidTis', eventId, {sex: sex, division: division}],
            queryFn: () => findRunnerTisTid(eventId, sex, division),
        });

        if (isSuccess) {
            return <>{data.data}</>;
        }

        if (isLoading) {
            return (<>...</>)
        }

        if (isError) {
            console.log(error);
        }

    




    return (<>
    
    </>)

}